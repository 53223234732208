<template>
    <v-app>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title pt-3 px-3 d-flex justify-content-between">
                <div class="breadcrumb-left">
                  <h4>Enrolment</h4>
                  <div class="breadcrumb-sub-header">
                    <router-link to="/dashboard">Dashboard</router-link>
                    \   Enrolment
                  </div>
                </div>
                <div class="breadcrumb-right" v-if="checkIsAccessible('academic-class','create')">
                  <div class="card-toolbar">
                    <v-btn  v-if="!loading"  @click="createEnrolment()"  class="mt-4 btn btn-primary" style="color: #fff">
                      <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                      Add enrolment
                    </v-btn>&nbsp;&nbsp;
                    <v-btn v-if="!loading"  @click="createCsv()"  class="mt-4 btn btn-primary" style="color: #fff">
                      <v-icon small elevation="2" outlined>fa-file-export</v-icon>&nbsp;
                      Export as CSV
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                <div class="row">
                  <v-col cols="12" sm="6" md="3">
                    <v-text-field label="Title" type="text" dense outlined
                                  v-model="search.name" placeholder="Name, Phone, Email"/>
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-select label="Program" :items="programs" item-value="id" item-text="title_text" outlined
                              dense v-model="search.program">
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">

                        <v-text-field
                            v-model="search.start_date"
                            placeholder="Start date"

                            readonly dense outlined
                            v-bind="attrs" prepend-icon=""
                            prepend-inner-icon="mdi-calendar"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="search.start_date"
                          header-color="primary"
                          @input="menu2=false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-menu
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">

                        <v-text-field
                            v-model="search.end_date"
                            placeholder="End date"

                            readonly dense outlined
                            v-bind="attrs" prepend-icon=""
                            prepend-inner-icon="mdi-calendar"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="search.end_date" header-color="primary"
                                     @input="menu3=false"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-select label="Status" outlined dense v-model="search.status" :items="statuses"
                              item-value="value" item-text="title">
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-select label="Limit" outlined dense v-model="search.limit" :items="[25,50,100,250,500]">
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-btn
                        @click.prevent="getPrograms()"
                        class="btn btn-primary btn-block w-35 float-right"
                        :loading="loading"
                        dark
                    >
                      <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                      Search
                    </v-btn>
                  </v-col>
                </div>
              </div>
              <div class="">
                <table class="table">
                  <thead class="text-left">
                    <tr class="px-3">
                      <th>#</th>
                      <th>Enrolment ID #</th>
                      <th>Programme</th>
                      <th>Applicant</th>
                      <th>Date</th>
                      <th>Payment</th>
                      <th>Status</th>
                      <th>Action</th>
                  </tr>
                  </thead>
                  <tbody class="text-left">
                      <tr v-for="(enrollment, index) of enrollments" :key="index">
                    <td> {{index+1}}</td>
                    <td>
                      <a href="#" @click.prevent="openDetailDialog(enrollment.id)">
                        {{enrollment.enrollment_id}}
                      </a>
                    </td>
                    <td>{{enrollment.program_detail ? enrollment.program_detail.short_title :
                        'NA'}}
                    </td>
                    <td>
                      <b>{{enrollment.full_name}}</b>
                      <br/>
                      <em>{{enrollment.email}}</em>
                      <br/>
                      {{enrollment.phone}} {{enrollment.mobile ? enrollment.mobile : ''}}
                      <br/>
                    </td>
                    <td>
                      <span v-if="enrollment.submitted_date">
                        {{enrollment.submitted_date}}
                      </span>
                      <span class="badge badge-secondary" v-else>-</span>
                    </td>
                    <!--                      <td></td>-->
                    <!--                      <td>-->
                    <!--&lt;!&ndash;                        <span>{{(enrollment.gender)?enrollment.gender:null}}</span>&ndash;&gt;-->
                    <!--                      </td>-->

                    <td>
                                        <span
                                            :class="enrollment.is_payment_completed ? 'badge badge-primary':'badge badge-warning'"
                                        >{{enrollment.is_payment_completed ? 'Complete':'Pending'}}</span>

                      <br/>
                      <span
                          class="badge badge-info mt-2"
                      >{{enrollment.payment_method ?enrollment.payment_method.charAt(0).toUpperCase()+enrollment.payment_method.slice(1): 'NA'}}</span>
                    </td>
                    <td>
                                                <span
                                                    :class="enrollment.is_submitted ? 'badge badge-primary':'badge badge-danger'"
                                                >{{enrollment.is_submitted ? 'Complete':'Draft'}}</span>
                    </td>

                    <td>
                      <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip
                      >
                        <template v-slot:button-content>
                          <slot>
                                                        <span>
                                                                    <i class="flaticon-more-1"></i>
                                                                  </span></slot>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover ">
                          <b-dropdown-text tag="div" class="navi-item"   v-if="user.type == 'admin' && enrollment.payment_method == 'cash' && !enrollment.is_payment_completed">
                            <a href="#" class="navi-link"  @click="openOfflinePayment(enrollment.id)">
                                                            <span class="navi-icon">
                                                              <i class="fas fa-money-bill "></i>
                                                            </span>
                              <span class="navi-text">Offline Payment</span>
                            </a>
                          </b-dropdown-text>

                          <b-dropdown-text tag="div" class="navi-item" v-if="!enrollment.is_submitted" >
                            <a href="#" class="navi-link"  @click="editEnrollment(enrollment.id)">
                                                            <span class="navi-icon">
                                                              <i class="flaticon-edit"></i>
                                                            </span>
                              <span class="navi-text"> Edit Enrollment</span>
                            </a>
                          </b-dropdown-text>

                          <b-dropdown-text tag="div" class="navi-item">
                            <a href="#" class="navi-link" @click="openDetailDialog(enrollment.id)">
                                                            <span class="navi-icon">
                                                              <i class="fas fa-eye"></i>
                                                            </span>
                              <span class="navi-text">  View Detail</span>
                            </a>
                          </b-dropdown-text>
                          <!--                                                    <b-dropdown-text tag="div" class="navi-item" >-->
                          <!--                                                        <a href="#" class="navi-link">-->
                          <!--                                                            <span class="navi-icon">-->
                          <!--                                                              <i class="flaticon-edit"></i>-->
                          <!--                                                            </span>-->
                          <!--                                                            <span class="navi-text"> Edit Enrollment</span>-->
                          <!--                                                        </a>-->
                          <!--                                                    </b-dropdown-text>-->
                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>

                    </td>
                  </tr>
                      <tr v-if="enrollments.length == 0">
                        <td  class="px-3 text-center" colspan="8">No data available to display.</td>
                      </tr>
                  </tbody>
                </table>
                <b-pagination
                    @input="getEnrollments"
                    v-model="page" v-if="enrollments.length > 0"
                    :total-rows="total"  class="pull-right mt-7"
                    :per-page="perPage"
                    first-number
                    last-number
                ></b-pagination>
              </div>
              <enrollment-detail
                  :dialog="dialog"
                  :enrollment_id="enrollment_id"
                  @close_dialog="closeDialog"
              ></enrollment-detail>
            </div>
            <offline-payment
                    ref="offline-payment"
                    :enrollment_id="enrollment_id"
                    @close_modal="closeOfflinePayment"
            ></offline-payment>
        </div>
        </div>
      </div>
    </v-app>
</template>
<script>
    import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";
    import EnrollmentDetail from "./Detail";
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import LevelService from "@/core/services/level/LevelService";
    import ProgramService from "@/core/services/level/program/ProgramService";
    import OfflinePayment from "./offline-payment/Index";
    import {API_URL} from "@/core/config";

    const programService = new ProgramService();
    const levelService = new LevelService();
    const enrollmentService = new UserEnrollmentService();

    export default {
        components: {
            EnrollmentDetail,
            OfflinePayment
        },
        data() {
            return {
              loading:true,
                search: {
                    limit: 25,
                    program: "",
                    status: "complete",
                    start_date: "",
                    end_date: "",
                  type: "enrollment"
                },
                menu2: false,
                menu3: false,
                programs: [],
                statuses: [
                    {title: 'All', value: 'all'},
                    {title: 'Complete', value: 'complete'},
                    {title: 'Draft', value: 'draft'},
                ],
                closeOnClick: true,
                enrollments: [],
                dialog: false,
                enrollment_id: null,
                page: null,
                total: null,
                perPage: null,
                program_id: null,
                level_id: null,
                submitted_date: null
            };
        },
        computed: {
            user() {
                return this.$store.getters.currentUser;
            },
            isAdmin() {
                return this.user.type === "admin" ? true : false;
            }
        },
        mounted() {
            this.getEnrollments();
            this.getPrograms();
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Enrolments", route: "wizard-1"},
                {title: "My Enrolment"}
            ]);
        },
        methods: {
            getEnrollments() {
                enrollmentService
                    .paginate(this.search, this.page)
                    .then(response => {
                        this.enrollments = response.data.data;
                        this.page = response.data.meta.current_page;
                        this.total = response.data.meta.total;
                        this.perPage = response.data.meta.per_page;
                        this.loading= false
                    })
                    .catch((err) => {
                        // console.log(err)
                    }).finally(() => {
                          this.loading= false
                });
            },
            getPrograms() {
                programService.all().then(response => {
                    this.programs = response.data;
                });
            },
            openDetailDialog(id) {
                this.dialog = true;
                this.enrollment_id = id;
            },
            closeDialog() {
                this.dialog = false;
                this.enrollment_id = null;
            },
            openOfflinePayment(id) {
                this.enrollment_id = id;
                this.$refs["offline-payment"].showModal();
            },
            closeOfflinePayment() {
                this.enrollment_id = null;
                this.$refs["offline-payment"].hideModal();
                this.getEnrollments();
            },
            editEnrollment(id) {
                this.$router.push({name: "apply-enrolment-update", params: {id: id}});
            },
            // offlinePayement(id) {
            //   this.$router.push({ name: "offline-payement", params: { id: id } });
            // },
            searchEnrollment() {
                enrollmentService.paginate(this.search).then(response => {
                    this.enrollments = response.data.data;
                });
            },
            createEnrolment(){
              this.$router.push({ name: "apply-enrolment" });
            },
            createCsv() {
                let url = "";
                if (
                    this.search != null &&
                    this.search != undefined &&
                    this.search != ""
                ) {
                    var queryString = Object.keys(this.search)
                        .map(key => {
                            return (
                                encodeURIComponent(key) +
                                "=" +
                                encodeURIComponent(this.search[key])
                            );
                        })
                        .join("&");
                    if (queryString) {
                        url = url + "?" + queryString;
                    }
                }
                window.open(
                    API_URL + "enrollment-export-csv" + url,
                    "_blank"
                );
            }
        }
    };
</script>
