<template>
  <div>
    <b-modal
      id="modal-scrollable"
      size="xl"
      ref="my-modal"
      hide-footer
      title="Offline Payment"
    >
      <div class="d-block text-center">
        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-body">
                <div class="card-title">
                  <h4>Offline Payment</h4>
                </div>
                <div class="row">
                  <div class="col-4 form-group">
                    <label for class>Payer Name</label>
                    <input
                      type="text"
                      class="form-control border border-dark"
                      v-model="cart.payer_name"
                      placeholder="Payer Name"
                    />
                    <span
                      class="text-danger"
                      v-if="$v.cart.payer_name.$error"
                    >Payer namne is required</span>
                  </div>
                  <div class="col-4 form-group">
                    <label for class>Payer Email</label>
                    <input
                      type="email"
                      class="form-control border border-dark"
                      v-model="cart.payer_email"
                      placeholder="Payer Email"
                    />
                    <span
                      class="text-danger"
                      v-if="$v.cart.payer_email.$error"
                    >Payer email is required</span>
                  </div>
                  <div class="col-4 form-group">
                    <label for class>Payer Phone</label>
                    <input
                      type="email"
                      class="form-control border border-dark"
                      v-model="cart.payer_phone"
                      placeholder="Payer Phone"
                    />
                    <span
                      class="text-danger"
                      v-if="$v.cart.payer_phone.$error"
                    >Payer phone is required</span>
                  </div>
                  <div class="col-8 form-group">
                    <label for class>Payer Address</label>
                    <input
                      type="text"
                      class="form-control border border-dark"
                      v-model="cart.payer_address"
                      placeholder="Payer Address"
                    />
                    <span
                      class="text-danger"
                      v-if="$v.cart.payer_address.$error"
                    >Payer address is required</span>
                  </div>
                  <div class="col-4 form-group">
                    <label for class>Paid Amount</label>
                    <input
                      type="text"
                      class="form-control border border-dark"
                      v-model="cart.paid_amount"
                      placeholder="Paid Amount"
                    />
                    <span
                      class="text-danger"
                      v-if="$v.cart.paid_amount.$error"
                    >Paid amount is required</span>
                  </div>
                  <div class="col-4 form-group">
                    <label for class>Transaction Id</label>
                    <input
                      type="text"
                      class="form-control border border-dark"
                      v-model="cart.transaction_id"
                      placeholder="Paid Amount"
                    />
                    <span
                      class="text-danger"
                      v-if="$v.cart.transaction_id.$error"
                    >Trasnsaction Id is required</span>
                  </div>
                  <div class="col-8 mt-5 text-right">
                    <button
                      @click="closeDialog"
                      class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
                    >Cancel</button>
                    <button
                      @click="createOrUpdate"
                      class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
                    >Save</button>
                  </div>
                </div>
              </div>
            </div>
            <!-- <pre>{{user}}</pre>
      <pre>{{enrollment}}</pre>
            <pre>{{program}}</pre>-->
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
// import UserEnrollmentService from "@/core/services/user/"
import { required } from "vuelidate/lib/validators";
import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";
import UserService from "@/core/services/user/UserService";
import ProgramService from "@/core/services/level/program/ProgramService";
import CartService from "@/core/services/cart/CartService";

const enrollmentService = new UserEnrollmentService();
const userService = new UserService();
const programService = new ProgramService();
const cartService = new CartService();

export default {
  props: ["enrollment_id"],
  validations: {
    cart: {
      paid_amount: { required },
      payer_name: { required },
      payer_email: { required },
      payer_phone: { required },
      payer_address: { required },
      transaction_id: { required }
    }
  },
  data() {
    return {
      enrollment: {},
      user: {},
      program: {},
      cart_id: null,
      cart: {
        user_id: null,
        payment_method: "cash",
        paid_amount: null,
        order_status: "pending",
        payment_status: "pending",
        order_paid_time: null,
        order_placed_time: null,
        transaction_id: null,
        payer_email: null,
        invoice_id: null,
        payer_name: null,
        payer_phone: null,
        payer_address: null,
        is_processed: null
      }
    };
  },
  methods: {
    showModal() {
      this.getLoggedUser();
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    createOrUpdate() {
      this.$v.cart.$touch();
      if (this.$v.cart.$error) {
        setTimeout(() => {
          this.$v.cart.$reset();
        }, 4000);
      } else {
        this.addToCart();
      }
    },
    getProgram() {
      programService
        .show(this.enrollment.program_id)
        .then(response => {
          this.program = response.data.program;
          this.buildCart(response.data.program);
        });
    },
    buildCart(program) {
      this.cart.paid_amount = program.entrance_exam_fee;
      this.cart.cart_type = "enrollment";
      this.cart.item_price = program.entrance_exam_fee;
      this.cart.user_enrollment_id = this.enrollment_id;
    },
    getLoggedUser() {
      userService
        .logged()
        .then(response => {
          this.user = response.data;
          this.getEnrollment();
        })
        .catch(error => {
          //console.log(error);
        });
    },
    getEnrollment() {
        enrollmentService
          .show(this.user.id, this.enrollment_id)
          .then(response => {
            this.enrollment = response.data.enrollment;
            this.cart.payer_name = `${this.enrollment.first_name} ${this.enrollment.last_name}`;
            this.cart.payer_email = `${this.enrollment.email}`;
            this.cart.payer_phone = `${this.enrollment.phone}`;
            this.cart.payer_address = this.enrollment.full_address;
            this.cart.user_id = `${this.enrollment.user_id}`;
            this.getProgram();
          })
          .catch(error => {
            //console.log(error);
          });
    },
    addToCart() {
      cartService
        .addToCart(this.cart)
        .then(response => {
          this.cart_id = response.data.id;
          this.offlinePayment(this.cart_id);
        })
        .catch(error => {
          // console.log(error);
        });
    },
    offlinePayment(cartId) {
      cartService.payOffline(cartId).then(response => {
        this.$emit("close_modal");
      });
    },
    closeDialog(cartId) {
      this.$emit("close_modal");
    }
  }
};
</script>
